.form_holder {
  min-height: 100vh;
  padding-top: 70px;

  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-image: url("../images/form_bg.jpg");

  @include maxSM {
    min-height: initial;
    padding-top: 0;
  }

  @media (min-height: 970px) {
    display: flex;
    flex-direction: column;

    & > .container {
      margin-bottom: 40px;
    }

    .benefits {
      margin-top: auto;
    }
  }
}
