.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 70px;
  padding-top: 18px;
  padding-bottom: 18px;
  background-color: #fff;
  box-shadow: 2px 2px 20px 0 rgba(#000, 0.3);

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @include maxSM {
    position: fixed;
    height: 55px;
    padding-top: 11px;
    padding-bottom: 11px;

    &__phone {
      display: none;
    }
  }
}

.logo {
  display: inline-flex;
  align-items: center;
  text-decoration: none;

  &__txt {
    font-size: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
