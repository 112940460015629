.feedback {
  background: linear-gradient(45deg, #1f60ab 0%, rgba(31, 96, 171, 0.7) 45%, rgba(31, 96, 171, 0.7) 55%, #1f60ab 100%);

  &__title {
    margin-bottom: 35px;
    font-size: 52px;
    font-style: italic;
    font-weight: 300;
    color: #fff;
    text-shadow: 1px 1px 10px rgba(#000, 0.3);
    text-align: center;
  }

  @include maxSM {
    &__title {
      font-size: 36px;
    }
  }
}

.feedback_list {
  @include minSM {
    display: flex;
    justify-content: space-between;

    &__item {
      width: 30%;
    }
  }
}

.feedback_item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 15px 0 rgba(0, 53, 115, 0.8);
  text-align: center;

  &__quote {
    margin-bottom: 15px;
    font-size: 26px;
  }

  &__author {
    padding-top: 15px;
    border-top: 2px solid #ff6c00;
    font-size: 24px;
  }

  @include maxSM {
    padding: 25px 15px;
    &__quote, &__author {
      font-size: 22px;
    }
  }
}
