body {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  color: #4a4a4a;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 1.4;
}

.page_wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 100vh;
}

.content {
  flex: 1 1 auto;
  padding-top: 70px; // header height
  background-color: #f1f1f1;

  @include maxSM {
    padding-top: 55px; // mobile header height

    &__side {
      margin-bottom: 20px;
    }
  }
}

.section {
  padding-top: 40px;
  padding-bottom: 40px;

  @include maxSM {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.row--reverse {
  @include maxSM {
    display: flex;
    flex-direction: column-reverse;
  }
}

.icon {
  display: inline-block;
  overflow: hidden;
  vertical-align: top;

  svg {
    width: 100%;
    height: 100%;
    fill: inherit;
    background-color: inherit;
    vertical-align: top;
  }
}

.phone_number {
  color: currentColor;
  text-decoration: none;
  font-size: 20px;
  outline: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &--fixed {
    display: none;
    @include maxSM {
      position: fixed;
      bottom: 10px;
      right: 10px;
      z-index: 100;
      display: block;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      text-indent: -9999px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12),
      0 1px 5px 0 rgba(0, 0, 0, 0.2);
      background-image: url('../images/icon-phone-white.png');
      background-position: center;
      background-repeat: no-repeat;
      background-color: #1f60ab;
      transition: 0.3s;
    }
  }
}
