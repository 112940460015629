.unsubscribe {
  .form--unsubscribe {
    @include maxSM {
      margin-bottom: 20px;
    }
  }

  &__row + &__row {
    margin-top: 20px;
  }

  &__col + &__col {
    @include maxSM {
      margin-top: 20px;
    }
  }

  .input {
    padding: 20px 10px;

    border-radius: 0;
    border: 1px solid #979797;

    font-size: 16px;
  }
}
