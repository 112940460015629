.menu {
  padding-top: 30px;
  padding-bottom: 30px;

  font-size: 18px;
  text-align: center;

  border-bottom: 2px solid #494949;

  &__item {
    display: inline-block;
  }
  &__link {
    display: inline-block;
    padding: 5px 8px;
    border-radius: 3px;
    border: 1px solid transparent;

    //font-size: 16px;
    line-height: 1.5;

    text-decoration: none;

    &:hover {
      border-color: #4a4a4a;
    }
  }

  @include maxSM {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: none;

    &__item {
      display: block;

      & + & {
        border-top: 1px solid #ccc;
      }
    }
    &__link {
      display: block;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}
