.page_index {
  @include minSM {
    .content {
      padding-top: 0;
    }
    .header {
      background-color: transparent;
      box-shadow: none;
    }
  }
  .footer__divider {
    display: none;
  }
}
