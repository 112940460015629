.page_form {
  .form_holder {
    display: flex;
    align-items: center;
    background-position: center;

    .container {
      width: 100%;
    }

  }
  .footer__divider {
    display: none;
  }
  @include minSM {
    .content {
      padding-top: 0;
    }
  }
}
