.benefits {
  margin-top: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: rgba(#fff, 0.7);

  @include maxSM {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.benefits_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__item, &__item.slick-slide {
    display: flex;
    align-items: center;
    font-size: 22px;
  }
  &__item_txt {
    font-weight: bold;
  }
  &__item_icon {
    width: 70px;
    height: 70px;
    margin-right: 10px;
  }
  @include maxSM {
    display: block;
    padding-top: 20px;
    padding-bottom: 20px;

    &__item {
      flex-direction: column;
    }

    &__item_icon {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}
