.footer {

  .container {
    position: relative;
  }

  &__divider {
    position: absolute;
    top: -1px;
    left: 15px;
    right: 15px;
    height: 0;
    border-bottom: 1px solid #ccc;
  }

  &__disclosures {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &__copyright {
    padding-top: 30px;
    padding-bottom: 30px;

    background-color: #1f60ab;
    color: #fff;
    font-size: 14px;
    text-align: center;
  }

  @include maxSM {
    .container {
      display: flex;
      flex-direction: column;
    }

    &__disclosures {
      order: -1;
      border-bottom: 2px solid #494949;
    }

    &__divider {
      left: 0;
      right: 0;
    }
  }
}
